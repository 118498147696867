import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-version',
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->

    <p class="paragraph">
      <mark>v {{ version }}</mark>
      @if (apiEnv(); as apiEnv) {
        <mark [class.orange]="!apiEnv.sameEnv">API: {{ apiEnv.env }}</mark>
      }
    </p>
  `,
  styles: [
    `
      :host {
        background-color: var(--wzb-bo-primary);
        color: white;
        padding-inline: 1rem;
        border-top-left-radius: 10px;

        .paragraph {
          border: 1px solid;
          border-radius: 10px;
          margin: 3px;
          padding: 4px 0;
        }

        mark {
          display: block;
          font-size: small;
          color: white;
          background-color: transparent;
          font-weight: 600;

          &.orange {
            color: var(--wzb-bo-yellow);
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionComponent {
  readonly version = environment.version;

  readonly apiEnv = computed(() => {
    const apiEnv =
      environment.api.domain.includes('staging.k8s.wizbii.us') || environment.api.domain.includes('dev.k8s.wizbii.us')
        ? environment.api.domain.includes('staging.k8s.wizbii.us')
          ? 'staging'
          : 'dev'
        : undefined;

    if (window.location.origin.includes('localhost')) {
      return { env: apiEnv, sameEnv: false };
    }

    const env =
      window.location.origin.includes('staging.k8s.wizbii.us') || window.location.origin.includes('dev.k8s.wizbii.us')
        ? window.location.origin.includes('staging.k8s.wizbii.us')
          ? 'staging'
          : 'dev'
        : undefined;
    return apiEnv && env ? { env: apiEnv, sameEnv: apiEnv === env } : undefined;
  });
}
